import { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import api from '../services/api';
import { useAuth } from '../hooks/AuthProvider';
import { ThreeDots } from 'react-loader-spinner';

import Attantion from '../assets/images/attantion.svg';
import fileAdd from '../assets/images/file-add.svg';

function MyCatalog() {

    const { t, i18n } = useTranslation();
    const [partsCount, setPartsCount] = useState(null);
    const { cookies } = useAuth();

    const getData = async () => {
        const res = await api.get('/api/seller/stock/count', {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${cookies.token}`
            }
        });

        if (res.data.count) {
            setPartsCount(res.data.count);
        }

    }

    useEffect(() => {
        
        getData();

    }, []);

    return (
        <Container fluid className="p-5">
            <Row>
                <Col xs={12} xl={4}>
                    <Link to='/catalog/upload' className="btn btn-success p-3 w-100">
                        <img className="me-3" src={fileAdd} />
                        {t('addPartsWithFile')}
                    </Link>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col xs={12} md={6} xl={4}>
                    <Link to="/catalog" className="text-dark text-decoration-none">
                        <div className="bg-grey p-3">
                            <Row>
                                <Col xs={8}>
                                    <h6>
                                        {t('myParts')}
                                    </h6>
                                </Col>

                                <Col className="text-end">
                                    <img className="img-fluid" src={Attantion} />
                                </Col>
                            </Row>
                            
                            <h1 className="mt-4 fw-bold fst-italic">
                                {partsCount == null ?
                                    <ThreeDots 
                                        height="32" 
                                        width="32" 
                                        radius="9"
                                        color="#000" 
                                        ariaLabel="three-dots-loading"
                                    />
                                : partsCount}
                            </h1>
                        </div>
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}

export default MyCatalog