import axios from "axios";

export const baseUrl = () => {
    if (process.env.REACT_APP_MODE === 'prod') {
        return process.env.REACT_APP_PROD_BASE_URL;
    } else if (process.env.REACT_APP_MODE === 'test') {
        return process.env.REACT_APP_TEST_BASE_URL;
    }

    return process.env.REACT_APP_DEV_BASE_URL;
}

const api = axios.create({
    baseURL: baseUrl()
});

export default api;