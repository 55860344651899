import { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useAuth } from '../hooks/AuthProvider';
import { useTranslation } from 'react-i18next';
import api, { baseUrl } from '../services/api';
import calcCommission from "../services/calcCommission";
import { ThreeDots } from "react-loader-spinner";

import uploadImageImg from '../assets/images/image-upload.svg';
import removeImageimg from '../assets/images/remove-image.svg';

function PartModal(props) {

    const { callback } = props;

    const { t, i18n } = useTranslation();

    const { cookies } = useAuth();

    const [part, setPart] = useState(props.part);
    const [file, setFile] = useState(null);
    const [crossPart, setCrossPart] = useState(null);
    const [gradeDescription, setGradeDescription] = useState(props.part.grade_description);
    const [price, setPrice] = useState(props.part.price);
    const [weight, setWeight] = useState(props.part.weight);
    const [stock, setStock] = useState(props.part.stock);
    const [warranty, setWarranty] = useState(props.part.warranty);
    const [uploadingImage, setUploadingImage] = useState(false);

    const handleImageUpload = (e) => {
        setFile(e.target.files[0]);
    }

    const uploadImage = async (e) => {

        let formData = new FormData();
        formData.append('image', file);
        formData.append('pid', part.pid);

        setUploadingImage(true);

        const res = await api.post('/api/seller/stock/image/upload', formData, {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${cookies.token}`
            }
        }).catch(function (error) {
            setFile(null);
        });

        setFile(null);
        setPart({
            ...part,
            images: res.data.images
        });

        setUploadingImage(false);

    }

    const handleImageRemove = async (image) => {
        
        let formData = new FormData();
        formData.append('image', image);
        formData.append('pid', part.pid);

        const res = await api.post('/api/seller/stock/image/remove', formData, {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${cookies.token}`
            }
        }).catch(function (error) {
            console.log('Failed');
        });

        setFile(null);
        setPart({
            ...part,
            images: res.data.images
        });

    }

    const handleCrossPartRemove = async (partId) => {

        let formData = new FormData();
        formData.append('partId', partId);
        formData.append('pid', part.pid);

        const res = await api.post('/api/seller/stock/cross/remove', formData, {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${cookies.token}`
            }
        }).catch(function (error) {
            console.log('Failed');
        });

        setFile(null);
        setPart({
            ...part,
            cross: res.data.cross
        });

    }

    const addCrossPartHandler = async (e) => {

        let formData = new FormData();
        formData.append('partId', crossPart);
        formData.append('pid', part.pid);

        const res = await api.post('/api/seller/stock/cross/add', formData, {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${cookies.token}`
            }
        }).catch(function (error) {
            console.log('Failed');
        });

        setFile(null);
        setPart({
            ...part,
            cross: res.data.cross
        });
    }

    const updatePartHandler = async (e) => {

        let formData = new FormData();
        formData.append('price', price);
        formData.append('stock', stock);
        formData.append('weight', weight);
        formData.append('warranty', warranty);
        formData.append('gradeDescription', gradeDescription);
        formData.append('pid', part.pid);

        const res = await api.post('/api/seller/stock/update', formData, {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${cookies.token}`
            }
        }).catch(function (error) {
            console.log('Failed');
        });

        setFile(null);
        setPart({
            ...part,
            price: res.data.price,
            stock: res.data.stock,
            weight: res.data.weight,
            gradeDescription: res.data.grade_description
        });
        callback(false);

    }

    useEffect(() => {

        if ( file ) {
            uploadImage();
        }

    }, [file]);

    console.log(baseUrl());

    return (
        <Row>
            <Col xs={12} className="mb-4">
                <Form>
                    <h5 className="mb-3 fw-bold">{t('detailPhotos')}</h5>
                    <ul className="p-0 m-0 list-inline list-unstyled">
                        {part.images && part.images.length > 0 ? 
                            part.images.map((image, index) => (
                                <li className="list-inline-item me-3 position-relative" key={index}>
                                    <img className="part-images" src={baseUrl() + '/storage/' + image} />
                                    <Button
                                        className="remove-image position-absolute top-0 end-0 btn btn-link p-0"
                                        onClick={() => handleImageRemove(index)}
                                    >
                                        <img className="" src={removeImageimg} />
                                    </Button>
                                </li>
                            ))
                        : ''}
                        <li className="list-inline-item">
                            {uploadingImage ? 
                                <ThreeDots 
                                    height="32" 
                                    width="32" 
                                    radius="18"
                                    color="#000" 
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{
                                        justifyContent: "center"
                                    }}
                                />
                            :
                            <Form.Label style={{cursor: "pointer"}}>
                                <Form.Control type="file" className="opacity-0 position-absolute" onChange={handleImageUpload} />
                                <img src={uploadImageImg} />
                            </Form.Label>
                            }
                        </li>
                    </ul>
                </Form>
            </Col>

            <Col xs={12} md={6}>
                <Form>
                    <Row>
                        <Col xs={12}>
                            <h6 className="fw-bold mb-2">{part.name}</h6>
                            <h6 className="fw-bold text-success">{part.part_id}</h6>
                            <p>{t(part.condition)}</p>
                            <p>{part.condition == 'originalUsed' ? 'Grade ' + part.grade : ''}</p>
                            
                            <Form.Label className="fw-bold">{t('warrantyDay')}</Form.Label>
                            <Form.Control className="part-edit" value={warranty} onChange={e => setWarranty(e.target.value)}></Form.Control>

                            {part.condition == 'originalUsed' ?
                                <>
                                    <Form.Label>{t('gradeDescription')}</Form.Label>
                                    <Form.Text className="part-edit" style={{width: '100%'}} onChange={e => setGradeDescription(e.target.value)}></Form.Text>
                                </>
                            : ''}

                            <Row className="mt-4">
                                <Col xs={12} md={6} className="mb-3">
                                    <Form.Label className="fw-bold">{t('price')}</Form.Label>
                                    <Form.Control className="part-edit" value={price} onChange={e => setPrice(e.target.value)} />
                                </Col>

                                <Col xs={12} md={6} className="mb-3">
                                    <Form.Label className="fw-bold">{t('comission')}</Form.Label>
                                    <Form.Control className="part-edit" value={calcCommission(props.commissions, price)} readOnly />
                                </Col>

                                <Col xs={12} md={6} className="mb-3">
                                    <Form.Label className="fw-bold">{t('detailWeightKg')}</Form.Label>
                                    <Form.Control className="part-edit" value={weight} onChange={e => setWeight(e.target.value)} />
                                </Col>

                                <Col xs={12} md={6} className="mb-3">
                                    <Form.Label className="fw-bold">{t('qty')}</Form.Label>
                                    <Form.Control className="part-edit" value={stock} onChange={e => setStock(e.target.value)} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col>

            <Col xs={12} md={6}>
                <Form className="bg-grey h-100 rounded p-4">
                    <ul className="list-unstyled m-0 p-0">
                        {part.cross.length > 0 ? 
                            part.cross.map((item, index) => (
                                <li className="mb-4 position-relative" key={index}>
                                    <h6 className="fw-bold">SUB OEM Number</h6>
                                    <h6 className="fw-bold text-success">{item}</h6>

                                    <Button
                                        className="remove-image position-absolute top-0 end-0 btn btn-link p-0"
                                        onClick={() => handleCrossPartRemove(item)}
                                    >
                                        <img className="" src={removeImageimg} />
                                    </Button>
                                </li>
                            ))
                        : ''}
                    </ul>

                    <Form.Control className="part-edit" style={{width: '100%'}} onChange={e => setCrossPart(e.target.value)} placeholder={t('partId')}></Form.Control>
                    <Button variant="outline-secondary" className="w-100 mt-3" onClick={addCrossPartHandler}>{t('add')}</Button>
                </Form>
            </Col>

            <Col xs={12} className="text-center mt-4">
                <Button variant="secondary" className="mt-3 w-50 btn-lg" onClick={updatePartHandler}>
                    {t('save')}
                </Button>
            </Col>
        </Row>
    )
}

export default PartModal