import { Link } from "react-router-dom"

function MenuItem(props) {
    return (
        <li className={`mb-3 py-2 ${props.asideOpen ? '' : 'text-center'} ${(props.active && !props.asideOpen) ? 'bg-light' : ''}`}>
            <Link to={props.url} className="text-decoration-none">
                <img className={`me-2 menu-icon ${props.asideOpen ? '' : 'aside-img-grow'}`} src={props.image} />
                <span className={`${props.active ? 'fw-bold fst-italic' : ''} text-dark ${props.asideOpen ? 'd-inline-block' : 'd-none'}`}>{props.value}</span>
            </Link>
        </li>
    )
}

export default MenuItem