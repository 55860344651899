import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import menuArchive from '../assets/images/menu-archive.svg';
import CommingSoon from '../components/CommingSoon';

function ArchivePage() {

    const { t, i18n } = useTranslation();

    const breadcrumbs = {
        icon: menuArchive,
        items: [
            {
                url: 'archive',
                title: t('ordersArchive')
            }
        ]
    };

    return(
        <Layout page="archive" breadcrumbs={breadcrumbs} component={<CommingSoon />} />
    );

}

export default ArchivePage;