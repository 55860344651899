import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import menuHelp from '../assets/images/menu-help.svg';
import CommingSoon from '../components/CommingSoon';

function HelpPage() {

    const { t, i18n } = useTranslation();

    const breadcrumbs = {
        icon: menuHelp,
        items: [
            {
                url: 'help',
                title: t('help')
            }
        ]
    };

    return(
        <Layout page="help" breadcrumbs={breadcrumbs} component={<CommingSoon />} />
    );

}

export default HelpPage;