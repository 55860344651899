import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import menuCar from '../assets/images/menu-car.svg';
import CommingSoon from '../components/CommingSoon';

function VehiclesPage() {

    const { t, i18n } = useTranslation();

    const breadcrumbs = {
        icon: menuCar,
        items: [
            {
                url: 'vehicles',
                title: t('myVehicles')
            }
        ]
    };

    return(
        <Layout page="vehicles" breadcrumbs={breadcrumbs} component={<CommingSoon />} />
    );

}

export default VehiclesPage;