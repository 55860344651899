import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function CommingSoon() {

    const { t, i18n } = useTranslation();

    return (
        <Container fluid className="p-5">
            <Row>
                <Col className="text-center p-5 bg-grey rounded">
                    <h5 className="fw-bold text-uppercase">
                        {t('commingSoon')}
                    </h5>
                </Col>
            </Row>
        </Container>
    )
}

export default CommingSoon