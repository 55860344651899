import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import menuOrders from '../assets/images/menu-orders.svg';
import CommingSoon from '../components/CommingSoon';

function OrdersPage() {

    const { t, i18n } = useTranslation();

    const breadcrumbs = {
        icon: menuOrders,
        items: [
            {
                url: 'orders',
                title: t('myOrders')
            }
        ]
    };

    return(
        <Layout page="orders" breadcrumbs={breadcrumbs} component={<CommingSoon />} />
    );

}

export default OrdersPage;