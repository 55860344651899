export default function calcCommission(commissions, price) {
    let commission = 0;

    commissions.forEach(element => {
        if (price > element.min && price <= element.max) {
            commission = (price*element.percent/100).toFixed(2);
        }
    });

    return commission;
}