import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import menuCatalog from '../assets/images/menu-catalog.svg';
import MyAccount from '../components/MyAccount';

function HomePage() {

    const { t, i18n } = useTranslation();

    const breadcrumbs = {
        icon: menuCatalog,
        items: [
            {
                url: '',
                title: t('myAccount')
            }
        ]
    };

    return(
        <Layout page="home" breadcrumbs={breadcrumbs} component={<MyAccount />} />
    );

}

export default HomePage;