import { Container, Row, Col, Carousel } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import LoginBox from '../components/LoginBox';
import logo from '../assets/images/logo-yellow.svg'
import android from '../assets/images/android-black.svg'
import apple from '../assets/images/apple-black.svg'
import fb from '../assets/images/fb-black.svg'
import instagram from '../assets/images/instagram-black.svg'
import linkedin from '../assets/images/linkedin-black.svg'
import youtube from '../assets/images/youtube-black.svg'
import loginCarousel1 from '../assets/images/login-carousel-1.jpg';

function LoginPage() {

    const { t, i18n } = useTranslation();

    return(
        <Container fluid>
            <Row className="login-container">
                <Col xs={12} xl={6} className="login-l px-3 px-lg-5">
                    <div className="d-flex align-items-center h-25">
                        <a href="/"><img alt="viaro" src={logo} /></a>
                        <h2 className="text-uppercase fw-bold ms-3 mt-2">{t('autoPartsIS')}<br/>{t('digitalMarket')}</h2>
                    </div>
                    
                    <div className="login-box-container h-75 p-3 p-lg-4">
                        <div className="h-75">
                            <LoginBox />
                        </div>

                        <div className="h-25 d-flex align-items-end justify-content-center">
                            <div className="text-center">
                                <h6 className="fw-bold fst-italic">{t('downloadApp')}</h6>
                                <Row className="mt-2 mt-md-4 login-icons">
                                    <Col><a target="_blank" href="https://play.google.com/store/apps/details?id=ge.viaro.client2"><img alt="android" src={android} /></a></Col>
                                    <Col><a target="_blank" href="https://apps.apple.com/us/app/viaro-car-parts-app/id1623596609"><img alt="ios" src={apple} /></a></Col>
                                    <Col><a target="_blank" href="https://www.facebook.com/VIAROPARTSAPP"><img alt="facebook" src={fb} /></a></Col>
                                    <Col><a target="_blank" href="https://www.instagram.com/viaro.ge/"><img alt="instagram" src={instagram} /></a></Col>
                                    <Col><a target="_blank" href="https://www.linkedin.com/company/viaro/"><img alt="linkedin" src={linkedin} /></a></Col>
                                    <Col className="d-none d-md-block"><a target="_blank" href="https://www.youtube.com/@Viaro_Parts_APP"><img alt="youtube" src={youtube} /></a></Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col className="login-r d-none d-xl-block p-0">
                    <Carousel controls={false}>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 vh-100"
                                src={loginCarousel1}
                                alt="First slide"
                            />

                            <Carousel.Caption className="w-75 text-center m-auto">
                                <h3 className="fw-bold text-uppercase">{t('loginCarouselText1')}</h3>
                            </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                            <img
                                className="d-block w-100 vh-100"
                                src={loginCarousel1}
                                alt="Second Slide"
                            />

                            <Carousel.Caption className="w-75 text-center m-auto">
                                <h3 className="fw-bold text-uppercase">{t('loginCarouselText1')}</h3>
                            </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                            <img
                                className="d-block w-100 vh-100"
                                src={loginCarousel1}
                                alt="First slide"
                            />

                            <Carousel.Caption className="w-75 text-center m-auto">
                                <h3 className="fw-bold text-uppercase">{t('loginCarouselText1')}</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>


                    {/* <div className="h-100 d-flex align-items-end justify-content-center pb-5">
                        <h3 className="text-light text-uppercase text-center px-5">{t('loginCarouselText1')}</h3>
                    </div> */}
                </Col>
            </Row>
        </Container>
    );
}

export default LoginPage;