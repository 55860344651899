import { useState } from 'react';
import { AuthProvider } from './AuthProvider';
import { ContextProvider } from './ContextProvider';

function AppProvider({ children }) {

    const [asideOpen, setAsideOpen] = useState(true);

    return(
        <AuthProvider>
            <ContextProvider.Provider value={ { asideOpen, setAsideOpen } }>
                { children }
            </ContextProvider.Provider>
        </AuthProvider>
    );
}

export default AppProvider;