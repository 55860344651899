import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import menuCatalog from '../assets/images/menu-catalog.svg';
import File from '../components/File';

function FilePage() {

    const { t, i18n } = useTranslation();

    const breadcrumbs = {
        icon: menuCatalog,
        items: [
            {
                url: 'catalog',
                title: t('myCatalog')
            },
            {
                url: 'file',
                title: t('editFile')
            }
        ]
    };

    return(
        <Layout page="catalog" breadcrumbs={breadcrumbs} component={<File />} />
    );

}

export default FilePage;