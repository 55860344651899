import { useTranslation } from 'react-i18next';

function ErrorPage() {

    const { t, i18n } = useTranslation();

    return(
        <>
            <h1>404</h1>
        </>
    );

}

export default ErrorPage;