import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row, Table, Modal, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useAuth } from '../hooks/AuthProvider';
import { useNavigate } from "react-router-dom";
import api, { baseUrl } from '../services/api';
import { ThreeDots } from "react-loader-spinner";
import calcCommission from "../services/calcCommission";

import noImg from '../assets/images/no-img.svg';
import PartModal from "./PartModal";

function File() {

    const { t, i18n } = useTranslation();

    const { fileId } = useParams();
    const [xlsId, setXlsId] = useState(null);
    const [filename, setFilename] = useState('');
    const [error, setError] = useState(null);
    const [sheets, setSheets] = useState([]);
    const [commissions, setCommissions] = useState([]);
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true)
    const [sheetSelected, setSheetSelected] = useState(null);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const { cookies } = useAuth();
    const navigate = useNavigate();
    const [selectedPart, setSelectedPart] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const [stockData, setStockData] = useState({}); 

    const getXls = async () => {

        const res = await api.get('/api/seller/xls/' + fileId, {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${cookies.token}`
            }
        }).catch(function (error) {
            // navigate('/404');
        });

        if (res.status == 200 && res.data) {

            setXlsId(res.data.id);
            setFilename(res.data.file_name);
            setSheets(res.data.sheets);
            setSheetSelected(res.data.sheets[0]);
            setCommissions(res.data.commission);

        } else {
            navigate('/404');
        }

    }

    const getData = async () => {

        const res = await api.get('/api/seller/stock/' + xlsId + '/' + sheetSelected + '?page=' + page, {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${cookies.token}`
            }
        });

        if (res.status == 200 && res.data) {
            setData(res.data.data);
            setPages(res.data.last_page);
            setPage(res.data.current_page);
            setStockData(res.data);
        }

    }

    useEffect(() => {

        getXls();

    }, []);

    useEffect(() => {

        if (xlsId && page && sheetSelected) {
            getData();
            setLoader(false);
        }

    }, [sheetSelected, page]);

    const handeSheetChange = (e) => {
        setSheetSelected(e.currentTarget.value);
        setPage(1);
        setPages(0);
    }

    const handleModal = (part) => {
        setModalShow(true);
        setSelectedPart(part);
    }

    const handleClose = () => {
        setModalShow(false);
        getData();
    };

    const modalCallback = (state) => {
        setModalShow(state);
        getData();
     }

    return (
        <Container fluid className="p-5">
            
            <Modal show={modalShow} onHide={handleClose} size="lg">
                <Modal.Header closeButton></Modal.Header>

                <Modal.Body className="p-4">
                    <PartModal part={selectedPart} commissions={commissions} callback={modalCallback} />
                </Modal.Body>
            </Modal>

            {!loader ?
                <>
                    <Row>
                        <Col xs={12}>
                            <h4 className="fw-bold mb-3 p-0">
                                {t('addPartsWithXls')}
                            </h4>
                        </Col>

                        <Col xs={12}>
                            <h5 className="fw-bold text-success">
                                {filename}
                            </h5>

                            <p className="text-danger w-50 mt-3">
                                {t('photoRequiredWhenUsed')}
                            </p>

                            <p className="mt-4">
                                {t('addSelectedPages')}
                            </p>
                        </Col>
                    </Row>

                    {sheets ? 
                        <Row className="mb-5">
                            {sheets.map((sheet, index) => (
                                <Col className="d-flex align-items-center" key={'sheet_' + index}>
                                    <input
                                        name="sheet"
                                        type="radio"
                                        value={sheet}
                                        id={'sheet_' + index}
                                        onChange={handeSheetChange}
                                        checked={(sheetSelected && sheetSelected == sheet) ? true : false}
                                    />
                                    <label className="ms-2 bg-grey p-1 rounded" htmlFor={'sheet_' + index}>{sheet}</label>
                                </Col>
                            ))}
                        </Row>
                    : ''}

                    {data ? 
                    <Row>
                        <Col xs={12}>
                            <Table responsive bordered>
                                <thead className="bg-success">
                                    <tr>
                                        <th className="py-3" style={{borderRadius: "0.375rem 0 0 0"}}>&nbsp;</th>
                                        <th className="py-3">&nbsp;</th>
                                        <th className="py-3">ბრენდი</th>
                                        <th className="py-3">დასახელება</th>
                                        <th className="py-3">გრეიდი</th>
                                        <th className="py-3">ნაწილის ID</th>
                                        <th className="py-3">ქროსები</th>
                                        <th className="py-3">წონა</th>
                                        <th className="py-3">გარანტია</th>
                                        <th className="py-3">მომზადებს დრო</th>
                                        <th className="py-3">მარაგი</th>
                                        <th className="py-3">ფასი</th>
                                        <th className="py-3" style={{borderRadius: "0 0.375rem 0 0"}}>საკომისიო</th>
                                    </tr>
                                </thead>

                                <tbody className="bg-grey">
                                    {data.map((part, index) => (
                                        <tr key={'part_' + index} style={part.status != 1 ? {backgroundColor: '#FFF9BF'} : {}}>
                                            <th className="align-middle">{index + 1 + (page-1) * 50}</th>
                                            <th className="text-center">
                                                <Button variant="link">
                                                    <img style={{width: '40px', height: 'auto'}} src={part.images && part.images.length > 0 ? baseUrl() + '/storage/' + part.images[0] : noImg} onClick={() => handleModal(part)} />
                                                </Button>
                                            </th>
                                            <th className="align-middle">{part.brand}</th>
                                            <th className="align-middle">{part.name}</th>
                                            {part.condition == 'originalUsed' ? <th className="align-middle">{part.grade}</th> : <th className="align-middle">&nbsp;</th>}
                                            <th className="align-middle">{part.part_id}</th>
                                            <th className="align-middle">{part.cross.join(', ')}</th>
                                            <th className="align-middle">{part.weight}</th>
                                            <th className="align-middle">{part.warranty}</th>
                                            <th className="align-middle">
                                                {part.preparation_days ? `${part.preparation_days} ${t('days')}` : `${part.preparation_hours} ${t('hours')}` }
                                            </th>
                                            <th className="align-middle">{part.stock}</th>
                                            <th className="align-middle">{part.price}</th>
                                            <th className="align-middle">{calcCommission(commissions, part.price)}</th>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>

                        <Col xs={12} md={6} className="mt-4 mb-4">
                            <ul className="m-0 p-0 list-unstyled list-inline">
                                {stockData.prev_page_url ? <li className="list-inline-item"><Button className="fw-bold text-light" variant='success' onClick={() => setPage(1)}>{'<<'}</Button></li> : '' }
                                {stockData.prev_page_url ? <li className="list-inline-item"><Button className="fw-bold text-light" variant='success' onClick={() => setPage(page-1)}>{'<'}</Button></li> : '' }
                                <li className="list-inline-item"><Button className="fw-bold text-light" variant='warning'>{page}</Button></li>
                                {stockData.next_page_url ? <li className="list-inline-item"><Button className="fw-bold text-light" variant='success' onClick={() => setPage(page+1)}>{'>'}</Button></li> : '' }
                                {stockData.next_page_url ? <li className="list-inline-item"><Button className="fw-bold text-light" variant='success' onClick={() => setPage(stockData.last_page)}>{'>>'}</Button></li> : '' }
                            </ul>
                        </Col>

                        <Col xs={12} md={6} className="mt-4 text-end">
                            <Link to="/catalog" className="btn btn-success">დასრულება</Link>
                        </Col>
                    </Row>
                    : ''}
                </>
            : 
                <div className="text-center w-100">
                    <ThreeDots 
                        height="64" 
                        width="64" 
                        radius="18"
                        color="#000" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                            justifyContent: "center"
                        }}
                    />
                    <h6 className="fw-bold">
                        {t('loading')}
                    </h6>
                </div>
            }
        </Container>
    );
}

export default File;