import { Container, Row, Col, Button, Breadcrumb, Dropdown } from "react-bootstrap";
import Breadcrumbs from "./Breadcrumbs";
import { useTranslation } from 'react-i18next';
import { useAuth } from '../hooks/AuthProvider';
import userIcon from '../assets/images/user-icon.svg'
import roundedArrowDown from '../assets/images/rounded-arrow-down.svg';
import bellWNotification from '../assets/images/bell-with-notification.svg';

function Header(props) {

    const { t, i18n } = useTranslation();
    const { cookies } = useAuth();
    const { logout } = useAuth();

    return(
        <header className="py-3">
            <Container fluid className="px-5">
                <Row>
                    <Col className="d-flex align-items-center">
                        <Breadcrumbs element={props.breadcrumbs} />
                    </Col>

                    <Col className="d-flex align-items center justify-content-end">
                        <Dropdown>
                            <Dropdown.Toggle className="user-btn bg-light border-0">
                                <img className="img-fluid" src={userIcon} />
                                <span className="ms-3">{cookies.name}</span>
                                <img className="ms-5" src={roundedArrowDown} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item className="bg-light p-0">
                                    <Button variant="link" className="text-decoration-none text-danger" onClick={logout}>{t('signOut')}</Button>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Button variant="link" className="ms-4">
                            <img className="w-75" src={bellWNotification} />
                        </Button>
                    </Col>
                </Row>
            </Container>
        </header>
    );
}

export default Header;