import { FloatingLabel, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRef, useState, useEffect, useContext } from 'react';
import { useAuth } from '../hooks/AuthProvider';
import { ThreeDots } from 'react-loader-spinner';
 
function LoginBox() {

    const { t, i18n } = useTranslation();

    const phoneRef = useRef();
    const errRef = useRef();

    const [phone, setPhone] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [loaderVisible, setLoaderVisible] = useState(false);

    const { login } = useAuth();

    useEffect(() => {
        phoneRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [phone, pwd]);

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!phone || !pwd) {
            setErrMsg(t('enterCredentials'));
            return;
        }

        setLoaderVisible(true);
        const authorize = await login({ phone, pwd });
        setLoaderVisible(false);

        if (!authorize) {
            setErrMsg(t('incorrectCredentials'));
        }
    }

    return(
        <section className="p-4">
            <h3 className="text-uppercase text-primary fw-bold">{t('authorization')}</h3>
            <p className="mb-3">{t('authEnterCredentials')}</p>
            <Form onSubmit={handleLogin}>
                <Form.Group>
                    <FloatingLabel
                        controlId="userPhone"
                        label={t('enterPhoneNumber')}
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            placeholder={t('enterPhoneNumber')}
                            className="grey-input"
                            ref={phoneRef}
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </FloatingLabel>

                    <FloatingLabel
                        controlId="userPassword"
                        label={t('enterPassword')}
                    >
                        <Form.Control
                            type="password"
                            placeholder={t('enterPassword')}
                            className="grey-input"
                            onChange={(e) => setPwd(e.target.value)}
                        />
                    </FloatingLabel>

                    <Button type="submit" variant="primary" className="w-100 mt-3 btn-lg auth-btn p-3 fw-bold text-uppercase">
                        <ThreeDots 
                            height="32" 
                            width="32" 
                            radius="9"
                            color="#000" 
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                                display: loaderVisible ? "inline-block" : "none",
                                marginRight: "1rem"
                            }}
                        />
                        {t('authorization')}
                    </Button>
                </Form.Group>
            </Form>
            
            {errMsg ?
                <p ref={errRef} className="text-danger text-center py-3">{errMsg}</p>
                : ''
            }
        </section>
    );

}

export default LoginBox;