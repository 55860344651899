import { createContext, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import api from '../services/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const navigate = useNavigate();
    const [cookies, setCookies, removeCookies] = useCookies();

    const login = async ({phone, pwd}) => {
        try {
            const res = await api.post('/api/login', {
                mobile: phone,
                password: pwd,
                role: 2,
            }, {
                withCredentials: false,
                headers: {
                    Accept: 'application/json'
                }
            })

            setCookies('token', res.data.token);
            setCookies('name', res.data.user.firstname);

            navigate('/');

        } catch ( err ) {
            return false;
        }

    }

    const logout = () => {
        ['token', 'name'].forEach(obj => removeCookies(obj));
        navigate('/login');
    }

    const value = useMemo(
        () => ({
            cookies,
            login,
            logout
        }),
        [cookies]
    );

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
}