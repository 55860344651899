import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import menuCatalog from '../assets/images/menu-catalog.svg';
import UploadSuccess from '../components/UploadSuccess';

function UploadSuccessPage() {

    const { t, i18n } = useTranslation();

    const breadcrumbs = {
        icon: menuCatalog,
        items: [
            {
                url: 'catalog',
                title: t('myCatalog')
            }
        ]
    };

    return(
        <Layout page="catalog" breadcrumbs={breadcrumbs} component={ <UploadSuccess /> } />
    );

}

export default UploadSuccessPage;