import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from '../hooks/AuthProvider';
import api from '../services/api';
import { Col, Container, Row } from "react-bootstrap";
import { ThreeDots } from 'react-loader-spinner';

import checkbox from '../assets/images/checbox.svg';

function UploadSuccess() {

    const { t, i18n } = useTranslation();

    const { fileId } = useParams();
    const [xlsId, setXlsId] = useState(null);
    const [filename, setFilename] = useState('');
    const [sheets, setSheets] = useState([]);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(true);
    const { cookies } = useAuth();

    const getXls = async () => {

        const res = await api.get('/api/seller/xls/' + fileId, {
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${cookies.token}`
            }
        });

        if (res.status == 200 && res.data) {

            setXlsId(res.data.id);
            setFilename(res.data.file_name);
            setSheets(res.data.sheets);
            setLoader(false);

        }

    }

    useEffect(() => {

        getXls();

    }, []);

    return (
        <Container fluid className="p-5">
            <Row>
                <Col xs={12} className="bg-grey text-center rounded p-5">
                    {!loader ?
                        <>
                            <p>{t('uploadResults')}</p>
                            <h6 className="fw-bold">{filename}</h6>
                            <p className="mt-5">{t('totalUploaded')}: <strong>{sheets.length} {t('page')}</strong></p>

                            <Row className="justify-content-center mt-5">
                                <Col xs={12} md={8}>
                                    <Row>
                                        {sheets.map((sheet, index) => (
                                            <Col key={index}>
                                                <img src={checkbox} />
                                                <span className="bg-light p-2 ms-3 rounded">
                                                    {sheet}
                                                </span>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="mt-5">
                                <Col className="text-center">
                                    <Link className="btn btn-secondary px-5" to={"/file/" + fileId}>
                                        {t('continue')}
                                    </Link>
                                </Col>
                            </Row>
                        </>
                    :
                        <div className="text-center w-100">
                            <ThreeDots 
                                height="64" 
                                width="64" 
                                radius="18"
                                color="#000" 
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{
                                    justifyContent: "center"
                                }}
                            />
                            <h6 className="fw-bold">
                                {t('loading')}
                            </h6>
                        </div>
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default UploadSuccess;