import './App.scss';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ProtectedRoute } from './hooks/ProtectedRoute';
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from './hooks/AuthProvider';
import CatalogPage from './pages/Catalog';
import LoginPage from './pages/Login';
import ErrorPage from './pages/Error';
import Upload from './pages/Upload';
import VehiclesPage from './pages/Vehicles';
import OrdersPage from './pages/Orders';
import ArchivePage from './pages/Archive';
import FinancesPage from './pages/Finances';
import HelpPage from './pages/Help';
import FilePage from './pages/File';
import HomePage from './pages/Home';
import UploadSuccessPage from './pages/UploadSuccess';


function App() {

	<></>

	const navigate = useNavigate();
    const { cookies } = useAuth();

	const { fileId } = useParams();

	return (
		<Routes>
			<Route element={ <ProtectedRoute /> }>
				<Route path="/" element={ <HomePage /> } />
			</Route>
			
			<Route exact path="/login">
				{cookies.token ? <Route path="/login" element={<Navigate to='/home' exact />} /> : <Route path="/login" element={<LoginPage />} />}
			</Route>

			<Route element={ <ProtectedRoute /> }>
				<Route path='/catalog' element={ <CatalogPage /> } />
			</Route>

			<Route element={ <ProtectedRoute /> }>
				<Route path='/catalog/upload' element={ <Upload /> } />
			</Route>

			<Route element={ <ProtectedRoute /> }>
				<Route path='/catalog/upload-success/:fileId' element={ <UploadSuccessPage /> } />
			</Route>

			<Route element={ <ProtectedRoute /> }>
				<Route path='/file/:fileId' element={ <FilePage /> } />
			</Route>

			<Route element={ <ProtectedRoute /> }>
				<Route path='/vehicles' element={ <VehiclesPage /> } />
			</Route>

			<Route element={ <ProtectedRoute /> }>
				<Route path='/orders' element={ <OrdersPage /> } />
			</Route>

			<Route element={ <ProtectedRoute /> }>
				<Route path='/archive' element={ <ArchivePage /> } />
			</Route>

			<Route element={ <ProtectedRoute /> }>
				<Route path='/finances' element={ <FinancesPage /> } />
			</Route>

			<Route element={ <ProtectedRoute /> }>
				<Route path='/help' element={ <HelpPage /> } />
			</Route>

			<Route path="*" element={<ErrorPage />} />
		</Routes>
	);
}

export default App;
